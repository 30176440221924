var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"md:flex items-start md:columns-2"},[_vm._m(1),_c('div',{staticClass:"p-3 flex items-center justify-content-center"},[_c('div',[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                  group: 'chi_siamo',
                  src: '/storage/images/images/15834010115e60c833835cf__immagine.jpg',
                  title: 'Chi Siamo',
                }),expression:"{\n                  group: 'chi_siamo',\n                  src: '/storage/images/images/15834010115e60c833835cf__immagine.jpg',\n                  title: 'Chi Siamo',\n                }"}],staticClass:"img-fluid rounded py-3",staticStyle:{"cursor":"pointer"},attrs:{"src":"/storage/images/images/15834010115e60c833835cf__immagine.jpg","alt":"Chi Siamo","data-src":'/storage/images/images/15834010115e60c833835cf__immagine.jpg',"data-sizes":"auto"}})])])])])])]),_c('div',[_c('div',[_c('div',{staticClass:"container-fluid py-3"},[_c('h1',{staticClass:"mb-4"},[_vm._v("La nostra realtà")]),_c('section',{staticClass:"overflow-hidden text-gray-700"},[_c('div',{staticClass:"container py-2 mx-auto"},[_c('div',{staticClass:"flex flex-wrap -m-1 md:-m-2"},[_c('div',{staticClass:"flex flex-wrap w-1/4"},[_c('div',{staticClass:"w-1/2 p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg',
                      title: 'Le Masiere 1',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg',\n                      title: 'Le Masiere 1',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg","data-src":'/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg',"data-sizes":"auto"}})]),_c('div',{staticClass:"w-1/2 p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg',
                      title: 'Le Masiere 2',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg',\n                      title: 'Le Masiere 2',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg","data-src":'/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg',"data-sizes":"auto"}})]),_c('div',{staticClass:"w-full p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg',
                      title: 'Le Masiere 3',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg',\n                      title: 'Le Masiere 3',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg","data-src":'/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg',"data-sizes":"auto"}})])]),_c('div',{staticClass:"flex flex-wrap w-1/4"},[_c('div',{staticClass:"w-1/2 p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg',
                      title: 'Le Masiere 4',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg',\n                      title: 'Le Masiere 4',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg","data-src":'/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg',"data-sizes":"auto"}})]),_c('div',{staticClass:"w-1/2 p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg',
                      title: 'Le Masiere 5',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg',\n                      title: 'Le Masiere 5',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg","data-src":'/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg',"data-sizes":"auto"}})]),_c('div',{staticClass:"w-full p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg',
                      title: 'Le Masiere 6',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg',\n                      title: 'Le Masiere 6',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg","data-src":'/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg',"data-sizes":"auto"}})])]),_c('div',{staticClass:"flex flex-wrap w-1/4"},[_c('div',{staticClass:"w-1/2 p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg',
                      title: 'Le Masiere 7',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg',\n                      title: 'Le Masiere 7',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg","data-src":'/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg',"data-sizes":"auto"}})]),_c('div',{staticClass:"w-1/2 p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg',
                      title: 'Le Masiere 8',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg',\n                      title: 'Le Masiere 8',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg","data-src":'/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg',"data-sizes":"auto"}})]),_c('div',{staticClass:"w-full p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029925e60cff080228__immagine.jpg',
                      title: 'Le Masiere 9',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029925e60cff080228__immagine.jpg',\n                      title: 'Le Masiere 9',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029925e60cff080228__immagine.jpg","data-src":'/storage/images/gallery/15834029925e60cff080228__immagine.jpg',"data-sizes":"auto"}})])]),_c('div',{staticClass:"flex flex-wrap w-1/4"},[_c('div',{staticClass:"w-full p-1 md:p-2"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
                      group: 'Gallery',
                      src: '/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg',
                      title: 'Le Masiere 10',
                    }),expression:"{\n                      group: 'Gallery',\n                      src: '/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg',\n                      title: 'Le Masiere 10',\n                    }"}],staticClass:"block object-cover object-center w-full h-full rounded-lg",attrs:{"alt":"gallery","src":"/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg","data-src":'/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg',"data-sizes":"auto"}})])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"flex items-center justify-center h-96 mb-12 bg-fixed bg-center bg-cover",staticStyle:{"background-image":"linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url('/storage/images/hero/15857432545e848596366cf__immagine.jpg')","z-index":"0","opacity":"75"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br'),_c('h1',[_vm._v("Chi Siamo")]),_c('br'),_c('div',{staticClass:"text-justify my-3 line-clamp-2",staticStyle:{"color":"rgb(102, 102, 102)"},attrs:{"id":"news-text"}},[_c('p',{staticClass:"mb-2"},[_vm._v(" L’azienda agricola Le Masiere è situata nel cuore di Santo Stefano, tra le verdi colline del Valdobbiadene DOCG. Le vigne, tre ettari, sono tutte in paese e coltivate rigorosamente a mano. ")]),_c('p',[_vm._v(" In azienda si può degustare il Valdobbiadene DOCG Prosecco Superiore extra dry e il vino della tradizione meglio conosciuto come vino col fondo. ")])])])
}]

export { render, staticRenderFns }