import Vue from 'vue'
import App from './App.vue'
import './assets/style/style.css'
import VueImg from 'v-img';

const vueImgConfig = {
  altAsTitle: false,
  sourceButton: true,
  openOn: "click",
  thumbnails: false,
}


Vue.use(VueImg, vueImgConfig);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

