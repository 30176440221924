<template>
  <div>
    <div>
      <section
        class="flex items-center justify-center h-96 mb-12 bg-fixed bg-center bg-cover"
        style="
          background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url('/storage/images/hero/15857432545e848596366cf__immagine.jpg');
          z-index: 0;
          opacity: 75;
        "
      ></section>
    </div>
    <div>
      <div>
        <div class="container-fluid">
          <div class="md:flex items-start md:columns-2">
            <div>
              <br />
              <h1>Chi Siamo</h1>
              <br />
              <div
                id="news-text"
                class="text-justify my-3 line-clamp-2"
                style="color: rgb(102, 102, 102)"
              >
                <p class="mb-2">
                  L’azienda agricola Le Masiere è situata nel cuore di Santo
                  Stefano, tra le verdi colline del Valdobbiadene DOCG. Le
                  vigne, tre ettari, sono tutte in paese e coltivate
                  rigorosamente a mano.
                </p>
                <p>
                  In azienda si può degustare il Valdobbiadene DOCG Prosecco
                  Superiore extra dry e il vino della tradizione meglio
                  conosciuto come vino col fondo.
                </p>
              </div>
            </div>
            <div class="p-3 flex items-center justify-content-center">
              <div>
                <img
                  src="/storage/images/images/15834010115e60c833835cf__immagine.jpg"
                  alt="Chi Siamo"
                  class="img-fluid rounded py-3"
                  v-img="{
                    group: 'chi_siamo',
                    src: '/storage/images/images/15834010115e60c833835cf__immagine.jpg',
                    title: 'Chi Siamo',
                  }"
                  :data-src="'/storage/images/images/15834010115e60c833835cf__immagine.jpg'"
                  data-sizes="auto"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="container-fluid py-3">
          <h1 class="mb-4">La nostra realtà</h1>
          <section class="overflow-hidden text-gray-700">
            <div class="container py-2 mx-auto">
              <div class="flex flex-wrap -m-1 md:-m-2">
                <div class="flex flex-wrap w-1/4">
                  <div class="w-1/2 p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg',
                        title: 'Le Masiere 1',
                      }"
                      :data-src="'/storage/images/gallery/15834029815e60cfe5eb60a__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                  <div class="w-1/2 p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg',
                        title: 'Le Masiere 2',
                      }"
                      :data-src="'/storage/images/gallery/15834029825e60cfe60b3b7__immagine (1).jpg'"
                      data-sizes="auto"
                    />
                  </div>
                  <div class="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg',
                        title: 'Le Masiere 3',
                      }"
                      :data-src="'/storage/images/gallery/15834029845e60cfe8a6584__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                </div>
                <div class="flex flex-wrap w-1/4">
                  <div class="w-1/2 p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg',
                        title: 'Le Masiere 4',
                      }"
                      :data-src="'/storage/images/gallery/15834029855e60cfe9c94da__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                  <div class="w-1/2 p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg',
                        title: 'Le Masiere 5',
                      }"
                      :data-src="'/storage/images/gallery/15834029875e60cfeb43984__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                  <div class="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg',
                        title: 'Le Masiere 6',
                      }"
                      :data-src="'/storage/images/gallery/15834029885e60cfecb2b07__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                </div>
                <div class="flex flex-wrap w-1/4">
                  <div class="w-1/2 p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg',
                        title: 'Le Masiere 7',
                      }"
                      :data-src="'/storage/images/gallery/15834029905e60cfee63ac3__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                  <div class="w-1/2 p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg',
                        title: 'Le Masiere 8',
                      }"
                      :data-src="'/storage/images/gallery/15834029915e60cfef591bd__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                  <div class="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029925e60cff080228__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029925e60cff080228__immagine.jpg',
                        title: 'Le Masiere 9',
                      }"
                      :data-src="'/storage/images/gallery/15834029925e60cff080228__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                </div>
                <div class="flex flex-wrap w-1/4">
                  <div class="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg"
                      v-img="{
                        group: 'Gallery',
                        src: '/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg',
                        title: 'Le Masiere 10',
                      }"
                      :data-src="'/storage/images/gallery/15834029935e60cff198ea9__immagine.jpg'"
                      data-sizes="auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage_block",
  components: {},
};
</script>

<style></style>
