<template>
  <div>
    <div class="container-fluid">
      <div class="md:flex items-start md:columns-2">
        <div class="w-full h-full">
          <div class="text-justify my-3 line-clamp-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d694.0967449137662!2d12.03537642927285!3d45.90357229869431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47791f375765898d%3A0xe61818ba3849da9!2sVia%20Menegazzi%2C%201%2C%2031049%20Santo%20Stefano%20TV!5e0!3m2!1sit!2sit!4v1607090355686!5m2!1sit!2sit"
              height="600px"
              width="100%"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
        <div class="ml-2 w-full">
            <div class="items-end flex justify-end pr-2">
              <h2>Orari di apertura</h2>  
            </div>
          <figure class="table" style="margin: auto">
            <table>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Mattino</th>
                  <th>Pomeriggio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Lunedì</th>
                  <td>9:00-12:00</td>
                  <td>14:00-18:00</td>
                </tr>
                <tr>
                  <th>Martedì</th>
                  <td>9:00-12:00</td>
                  <td>14:00-18:00</td>
                </tr>
                <tr>
                  <th>Mercoledì</th>
                  <td>9:00-12:00</td>
                  <td>14:00-18:00</td>
                </tr>
                <tr>
                  <th>Giovedì</th>
                  <td>9:00-12:00</td>
                  <td>14:00-18:00</td>
                </tr>
                <tr>
                  <th>Venerdì</th>
                  <td>9:00-12:00</td>
                  <td>14:00-18:00</td>
                </tr>
                <tr>
                  <th>Sabato</th>
                  <td>su prenotazione/on reservation</td>
                  <td>su prenotazione/on reservation</td>
                </tr>
                <tr>
                  <th>Domenica</th>
                  <td>su prenotazione/on reservation</td>
                  <td>su prenotazione/on reservation</td>
                </tr>
              </tbody>
            </table>
          </figure>
          <div class="text-end pr-2">
            <p class="my-3 font-bold">Accettiamo pagamenti tramite POS</p>
            <p class="my-3">Tel.: +39 339 6399183 - +39 347 8754741</p>
            <p class="mt-3 font-bold">Email a cui contattarci:</p>
            <p class="my-3">bruna.minato@alice.it</p>
            <p class="my-3">giampaolo.miotto@alice.it</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage_block",
  components: {},
};
</script>

<style></style>
