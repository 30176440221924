<template>
  <div>
    <nav
      class="navbar navbar-light container-fluid"
      style="background: transparent"
    >
      <div class="flex items-center justify-center">
        <a
          href="/"
          class="navbar-brand d-flex align-items-center m-4 justify-content-center"
          ><img
            src="/storage/images/contacts/15833999975e60c43d8daee__immagine.svg"
            alt="logo"
            class="img-fluid mr-3"
            style="max-height: 104px"
        /></a>
      </div>
    </nav>
    <Homepage_block v-if="home" />
    <Contacts_block v-else />
    <footer
      id="footer"
      class="font-small"
      style="background-color: rgb(96, 125, 139); color: rgb(255, 255, 255)"
    >
      <div class="container-fluid p-3">
        <div class="row d-flex justify-content-start align-items-center">
          <div>
            <div class="col-12">
              <div class="row d-flex align-items-center mb-2">
                <img
                  src="/storage/images/contacts/15833999975e60c43d8daee__immagine.svg"
                  alt="logo"
                  class="img-fluid mr-3"
                  style="max-height: 45px"
                />
              </div>
            </div>
            <div class="col-12 mb-4">
              <p class="m-0"></p>
              <div>
                <span class="m-0">
                  Tel.: +39 339 6399183 - +39 347 8754741
                </span>
              </div>
              <br />
            </div>
          </div>
          <div class="w-full">
            <div class="container-fluid">
              <div class="row flex justify-start">
                <div class="col-md-4">
                  <div class="row flex justify-content-start pr-4">
                    <div class="col-12 p-0 mb-4 divider">
                      <a
                        href="#"
                        @click="home = true"
                        class="font-weight-bold text-uppercase h6"
                        style="color: rgb(255, 255, 255)"
                        >Homepage</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row flex justify-content-start pr-4">
                    <div class="col-12 p-0 mb-4 divider">
                      <a
                        href="#"
                        @click="home = false"
                        class="font-weight-bold text-uppercase h6"
                        style="color: rgb(255, 255, 255)"
                        >Contatti</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="container-fluid">
              <div class="row flex items-center justify-center">
                <div
                  class="col-lg-1 col-md-2 col-3 flex items-center justify-center"
                >
                  <a
                    href="https://www.facebook.com/BrunaMnato/"
                    class="m-4 social"
                    style="color: rgb(0, 0, 0)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        class="footer-copyright flex items-center justify-center py-2"
        style="background-color: rgb(247, 250, 252); color: rgb(0, 0, 0)"
        ><a href="https://www.prysma.it/" style="color: rgb(0, 0, 0)"
          ><button
            type="button"
            class="font-bold prysma flex items-center justify-center p-2"
          >
            <img
              src="/storage/images/prysma_srl.8608cf42.svg"
              width="22px"
              alt=""
            />
            <span class="ml-2" style="color: rgb(0, 0, 0)"
              >Powered By Prysma</span
            >
          </button></a
        ></span
      >
    </footer>
  </div>
</template>

<script>
import Homepage_block from "./components/Homepage_block.vue";
import Contacts_block from "./components/Contacts_block.vue";

export default {
  name: "App",
  metaInfo: {
    title: "Le Masiere",
  },
  data() {
    return {
      home: true,
    };
  },
  components: {
    Homepage_block,
    Contacts_block,
  },
};
</script>

<style></style>
